@import '../../scss/_vars/colors';

.sidebarSettingMenu{
  position: absolute !important;
  bottom: 0;
}
.activeNavItem{
  span{
    color: $successColor !important
  }
  svg, path{
    fill: $successColor !important;
    stroke: $successColor !important
  }

  background: rgba(52, 214, 149, 0.1) !important;
  border-radius: 10px  !important;
  margin-left: 15px  !important;
  margin-right: 15px  !important;
}
.inActiveNavItem{
  margin-left: 15px  !important;
  margin-right: 15px  !important;
}