.mainContainerStyle {
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: white;
    background-color: #142036;
  }
  
  .searchInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .dropDownContainer {
    width: 100%;
    font-size: 16px;
    color: white;
    border: none;
    align-items: center;
    padding: 10px;
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(149, 154, 169);
    background-color: #142036;
    margin-bottom: 2px;
  }
  

  .dropDownContainer::placeholder {
    color: rgb(149, 154, 169);
  }
  .closeIconContainer {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 5px;
  }
  
  .suggestionBox {
    padding-top: 10px;
    width: 100%;
    margin-bottom: 10px;
    background-color: #142036;
    overflow-y: auto;
    position: absolute;
    z-index: 13;
    height: 250px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 0.5px solid white;
    border-radius: 4px;
  }
  
  .itemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    border-top: 1px solid white;
    justify-content: center;
    padding: 8px;
    cursor: pointer;
  }
  

  .itemFirstRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 100%;
  }
  

  .subspecialtyInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
  
  
  .subItemContainer {
    background-color: rgba(153, 150, 145, 0.1) ;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  
  .marginHorizontal{
    margin: 0 10px;
  }

  