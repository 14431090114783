@import '../../scss/_vars/others';

.wrapper {
  width: $global-width;

  @media (max-width: 700px) {
    width: 100%;
  }
}

.specialtyContainer {
  border: solid 1px white;
  position: fixed;
  background-color: gray;
  z-index: 10;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  max-height: 150px;
  overflow-y: auto;
  width: 70%;
  @media (max-width: 700px) {
    width: 90%;
  }
}
