@import '../../scss/_vars/colors';
@import '../../scss/_vars/others';
.wrapper {
  width: $global-width;
  @media (max-width: 700px) {
    width: 100%;
  }
}

.formWrapper {
  background-color: $formWrapperBackgroundColor;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 10px;
 
}

.carousel {
  // width: 350px ;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow-y: visible;
  overflow-x: clip;
  // padding-top: 30px;
  .imagePreview {
    height: 10px;
    object-fit: 'cover';
  }
}

.descriptionCarousel {
  overflow-y: visible;
  overflow-x: clip;
  padding-top: 10px;
}

.feedAnonymousPost {
  span:nth-child(2){
    border: 1px solid white;
  }
}


.carousel-container {
  position: relative;
}

.nav-button {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.nav-button:hover {
  opacity: 1;
}

