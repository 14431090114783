@import '../../scss/_vars/colors';

.feedContainer {
  background-color: $formWrapperBackgroundColor;
  margin-top: 20px;
  border-radius: 10px;
}

.feedAvatar {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid $successColor;
}

.feedAvatarSmall {
  width: 35px !important;
  height: 35px !important;
  border: 1px solid $successColor;
}

.carousel {
  // width: 350px ;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow-y: visible;
  overflow-x: clip;
  padding-top: 30px;
}
.descriptionCarousel {
  overflow-y: visible;
  overflow-x: clip;
  padding-top: 10px;
}
.blurImage {
  filter: blur(10px);
}
.blurImageText {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.speechToTextMicWrapper {
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #142036;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.blinkStopListingIcon {
  animation: blinker 2s linear infinite;
}

.commentReplyAnonymousPost {
  span:nth-child(2){
    border: 1px solid white;
  }
}

.carousel-container {
  position: relative;
}

.nav-button {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.nav-button:hover {
  opacity: 1;
}

.sliderContainer {
  width: 10%;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.imageContainer {
  height: 80vh;
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #ccc; /* For Firefox */
}

.zoomableImage {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: contain;
  transform-origin: center top;
}