@import '../../scss/_vars/colors';

.topSegmantWrapper {
  position: relative;
  > div:first-child{
    @media (max-width: 420px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .userBasicDetails{
      @media (max-width: 420px) {
        padding: 0px;
        padding-top: 20px;
        text-align: center;
      }
    }
  }
}
.editProfileButtonWrapper {
  position: absolute;
  top: 0;
  right: 0;
}
