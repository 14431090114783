@import '../../scss/_vars/colors';

.inactiveStep {
  border-top: 4px solid rgba(255, 254, 254, 0.5);
  border-radius: 10px;
}

.activeStep {
  border-top: 4px solid $successColor !important;
  border-radius: 10px;
}
