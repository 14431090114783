.formWrapper {
  background: #0a1418;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 525px;
  height: 380px;
  margin-top: 20px;

  > form {
    height: 100%;
    width: 100%;
  }
  @media (max-width: 535px) {
    // Adjust the breakpoint to your needs
    width: 100%; // Make the width full-width on smaller screens

    > form {
      > div:first-child {
        padding: 20px;
      }
      > .wrapperNestedDiv {
        justify-content: center;
      }
    }
  }
}
.title {
  width: 525px;
  text-align: center;
  margin: auto;
  margin-top: 30px;

  @media (max-width: 535px) {
    // Adjust the breakpoint to your needs
    width: 100%; // Make the width full-width on smaller screens
  }
}
