@import '../../scss/_vars/colors';

.feedContainer {
  background-color: $formWrapperBackgroundColor;
  margin-top: 20px;
  border-radius: 10px;
}

.feedAvatar {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid $successColor;
}

.feedAvatarSmall {
  width: 35px !important;
  height: 35px !important;
  border: 1px solid $successColor;
}

.carousel {
  // width: 350px ;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow-y: visible;
  overflow-x: clip;
  padding-top: 30px;
}
.blurImage {
  filter: blur(10px);
}
.blurImageText {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: transparent;
  z-index: 1;
}
