@import '../../scss/_vars/colors';

.formWrapper {
  background: $formWrapperBackgroundColor;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 500px;
  min-height: 420px;
  margin-top: 30px;

  @media (max-width: 535px) {
    // Adjust the breakpoint to your needs
    width: 100%; // Make the width full-width on smaller screens

    > form > div:first-child {
      padding: 20px;
    }
  }
}
