@import '../../scss/_vars/colors';
.feedAvatar {
  width: 40px !important;
  height: 40px !important;
  border: 1px solid $successColor;
}

.feedAvatarSmall {
  width: 30px !important;
  height: 30px !important;
  border: 1px solid $successColor;
}

.messageBox_received {
  color: black;
  font-family: 'Nunito';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  background-color: rgba(255, 254, 254, 1);
  padding: 12px 16px 12px 16px;
  border-radius: 20px;

  max-width: 400px;
  text-align: justify;
}

.messageBox_sent {
  color: white;
  font-family: 'Nunito';
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  background-color: $successColor;
  padding: 12px 16px 12px 16px;
  border-radius: 20px;

  max-width: 400px;
  text-align: justify;
}
