@import '../../scss/_vars/colors';

.createAccountFormWrapper {
  background-color: $formWrapperBackgroundColor;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}

.termsAndConditionsFont {
  div {
    background-color: #0A1418 !important;
    font-size: 16px !important;
    h2 {
      text-align: center;
    }
  }
}
