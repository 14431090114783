@import './_vars/colors';
@import './body-background.scss';
@import './_vars/others';

@font-face {
  font-family: 'Publico Text Web';
  src: url('./24ccee2ccbf3d81b58e3ef583a2431e5.eot'); /* IE9*/
  src: url('./24ccee2ccbf3d81b58e3ef583a2431e5.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./24ccee2ccbf3d81b58e3ef583a2431e5.woff2') format('woff2'),
    /* chrome firefox */ url('./24ccee2ccbf3d81b58e3ef583a2431e5.woff') format('woff'),
    /* chrome firefox */ url('./24ccee2ccbf3d81b58e3ef583a2431e5.ttf') format('truetype'); /* chrome firefox opera Safari, Android, iOS 4.2+*/
}

body {
  color: white;
  padding: 0;
  margin: 0;
}

a {
  color: $successColor;
  text-decoration: none;
  font-style: italic;
  cursor: pointer;
}

.buttonDisabled {
  background-color: $disabledButtonColor !important;
  color: gray !important;
}

/* Scroll bar */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $successColor;
}

// sidebar
.appSidebar {
  .MuiDrawer-paper {
    background: $formWrapperBackgroundColor;
    border-right: 1px solid white;
  }
}

// header
.appHeader {
  background: $formWrapperBackgroundColor !important;
  box-shadow: none !important;
}

.annotation-tooltip-wrapper {
  position: relative;
  .annotation-tooltiptext {
    visibility: visible;
    width: max-content;
    min-width: 120px;
    background-color: rgba(52, 214, 149, 1);
    color: #fff;
    text-align: center;
    border-radius: 8px;
    padding: 0;

    font-size: 16px;
    line-height: 24px;

    box-shadow: 0px 12px 16px 0px rgba(0, 0, 0, 0.3);

    /* Position the tooltip */
    position: absolute;
    top: 50%;
    left: 25%;
    z-index: 1;
  }
  .annotation-tooltiptext::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 15%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent rgba(52, 214, 149, 1) transparent;
  }
}
