@import '../../scss/_vars/colors';
@import '../../scss/_vars/others';

.formWrapper {
  background-color: $formWrapperBackgroundColor;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 10px;
  width: $global-width;
  @media (max-width: 700px) {
    width: 100%;
  }
}

.editSaveBtn {
  height: 48px;
  font-size: 16px;
  width: 200px;

  @media (max-width: 420px) {
    width: 100%;
  }
}
