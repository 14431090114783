@import '../../scss/_vars/colors';


.recentChatsWrapper {
  width: 410px;
  min-width: 410px;
  display: inline-block;
  height: calc(100vh - 161px);
  @media (max-width: 1130px) {
    width: 98%;
    min-width: 98%;
  }
}

.backgroundColor {
  background-color: $formWrapperBackgroundColor;
}

.chatWindowWrapper {
  display: inline-block;
  height: calc(100vh - 185px);
  width: 100%;
  min-width: 430px;
  @media (max-width: 1130px) {
    width: 98%;
    min-width: 98%;
  }
}

.newChatPopUp {
  margin-top: 10px;
  width: 520px;
  height: 570px;
}

.activeNavItem {
  span {
    color: $successColor !important;
  }
  svg,
  path {
    fill: $successColor !important;
    stroke: $successColor !important;
  }

  background: rgba(52, 214, 149, 0.1) !important;
  border-radius: 10px !important;
  padding: 4px 12px 4px 8px;

}
.inActiveNavItem {
  padding: 4px 12px 4px 8px;
}
